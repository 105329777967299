import React, { useState, useEffect } from 'react'
import './App.css'

function App () {
  const [winners, setWinners] = useState([])

  useEffect(() => {
    async function init () {
      const response = await window.fetch('winners.json')
      const winnersJSON = await response.json()
      console.log(winnersJSON)
      const asArray = Object.keys(winnersJSON)
        .map(k =>
          ({ wins: winnersJSON[k], account: k })
        )
        .map(winner => ({
          ...winner,
          wins: winner.wins
            .map(w => ({
              ...w,
              createdAt: new Date(w.createdAt)
            })).sort((a, b) =>
              b.createdAt.getTime() - a.createdAt.getTime()
            )
        }))

      setWinners(asArray)
    }
    init()
  }, [])

  function getHeat (date) {
    const timeDiff = (new Date()).getTime() - date.getTime()
    const days = 24 * 60 * 60 * 1000

    if (timeDiff < 1 * days) {
      return 3
    }
    if (timeDiff < 2 * days) {
      return 2
    }
    if (timeDiff < 5 * days) {
      return 1
    }
    return 0
  }

  function getHeatDisplay (date) {
    const heat = getHeat(date)
    return '🔥'.repeat(heat)
  }

  return (
    <div className='App'>
      <h1>
          Wins
      </h1>
      {winners && winners
        .filter(w => w.wins.length)
        .map(w =>
          <div key={w.account}>
            <h2>{w.account}</h2>
            {w.wins.map(notif =>
              <div key={notif.id} className='contest-win'>

                {notif.avatar && <img className='avatar' src={notif.avatar} />}

                <div className='contest-win-details'>
                  <div className='time'>
                    {getHeatDisplay(notif.createdAt)} <em>{notif.createdAt.toLocaleDateString()}</em>
                  </div>
                  <div>
                    <a href={`https://playr.gg/contest/${notif.contest}`} className='App-link'>{notif.title}</a>
                  </div>
                </div>
              </div>
            )}

          </div>
        )}

    </div>
  )
}

export default App
